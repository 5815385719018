import React, { useState, useRef } from 'react';
import Modal from '../Modal-wrapper/modal';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { scheduleMessages } from '../../actions/whatsAppActions';

const scheduleMessageForSingleUser = ({ data, onClose, onSubmit }) => {

    const [value, setValue] = useState()

    const onScheduleSubmit = async () => {
        const schedule_date = moment(value['$d']).utc().format('YYYY-MM-DD HH:mm:ss');
        const DataToBeSubmitted = [{ ...data, schedule_date }];
        console.log('DataToBeSubmitted :', DataToBeSubmitted)
        const result = await scheduleMessages({msg_obj_list:  DataToBeSubmitted })
        console.log("after submitting data", result);
        onSubmit(result);
        onClose();
    }

    return (
        <>
            <Modal onClose={onClose}>
                <Card>
                    <CardBody>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} style={{ zIndex: '200000 !important' }}>
                                <DateTimePicker
                                    onChange={(newValue) => setValue(newValue)}
                                />
                            </LocalizationProvider>
                        </Row>
                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button status="Info" type="button" shape="SemiRound"
                                style={{ margin: '10px', display: 'block' }}
                                onClick={onScheduleSubmit}
                                disabled={!value ? true : false}
                            >
                                Schedule Message
                            </Button>
                        </Row>
                    </CardBody>

                </Card>
            </Modal>
        </>
    )
}

export default scheduleMessageForSingleUser;
