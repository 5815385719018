import React, { useState } from "react";
import "./modal.css";
import Form from './modalForm'

const Modal = ({ onClose, templateName, variables, categoryList, nameList }) => {
  return (
    <>
      <div className="darkBG" />
      <div className="centered">
        <Form onClose={onClose} templateName={templateName} variables={variables} categoryList={categoryList} nameList={nameList}/>
      </div>
    </>
  );
};

export default Modal;