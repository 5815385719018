import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import Select from '@paljs/ui/Select';
import styled from 'styled-components';
import { postTemplate, getTemplateByName, updateTemplate } from '../../actions/templateAction';
import SelectOptions from '../villages/select-options'
import { margin } from '@mui/system';
import { getLoggedInUser } from '../../actions/authAction';


const SelectStyled = styled(Select)`
  margin-bottom: 1rem;
  width: 10rem;
`;


const Form = ({ onClose, templateName, variables, categoryList, nameList }) => {
  const [name, setName] = useState('');
  const [row, setRow] = useState({})
  const [message, setMessage] = useState('');
  const [category, setCategory] = useState('')
  const [createdBy, setCreatedBy] = useState('')


  useEffect(() => {
    const loggedInNode = getLoggedInUser();
    setCreatedBy(loggedInNode.name);
    getEditTemplate();
  }, [])


  const getEditTemplate = async () => {
    const response = templateName ? (await getTemplateByName(templateName)) : null
    console.log("response of details by edit template", response)
    setRow(response?.data)
    setName(response?.data?.template_name);
    setCategory(response?.data?.category);
    setMessage(response?.data?.message)
  }
  const templateVariables_user = []
  const templateVariables_addedby = []
  const templateVariables_registeredby = []

  if (variables?.user) {
    Object.keys(variables.user).map((field) => {
      templateVariables_user.push({ value: field, label: field })

    })
  }

  if (variables?.added_by) {
    Object.keys(variables.added_by).map((field) => {
      templateVariables_addedby.push({ value: field, label: field })
    })
  }

  if (variables?.registered_by) {
    Object.keys(variables.registered_by).map((field) => {
      templateVariables_registeredby.push({ value: field, label: field })
    })
  }

  const createNewTemplate = async () => {
    const data = {
      "template_name": name.trim(),
      "message": message,
      'created_by': createdBy,
      "category": category.trim()
    }
    const response = templateName ? (await updateTemplate(templateName, data)) : (await postTemplate(data))
    onClose();
    console.log('***data***, response', data, "******", response);
  }
  const messageAppend = (variable_type, value) => {
    setMessage(() => {
      const tempMessage = message ? message : ''
      return (tempMessage + '${' + `${variable_type}.${value}` + '}')
    })
  }
  return (
    <>
      <Card status="Info">
        <CardHeader>New Template</CardHeader>
        <CardBody >
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <InputGroup style={{ margin: '10px', display: 'block' }}>
                <div>Category : </div>
                {/* <input
                  placeholder='Enter Category'
                  onChange={(event) => setCategory(event.target.value)}
                  value={category}
                /> */}
                <div style={{ width: '200px' }}>
                  <SelectOptions options={categoryList} onSelect={setCategory} value={category} name={'Enter Category'} type={'creatable'} />
                </div>
              </InputGroup>
              <InputGroup style={{ margin: '10px', display: 'block' }} status={name && (templateName || (nameList.includes(name) != true)) ? 'Success' : 'Danger'}>
                <div>Name : </div>
                <input
                  placeholder='Enter Name'
                  onChange={(event) => {setName(event.target.value)}}
                  value={name}
                />
              </InputGroup>

            </Col>
          </Row>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <InputGroup style={{ display: 'block' }}>
                <div>Add variable for user : </div>
                <div style={{ width: '250px' }}>
                  <SelectStyled options={templateVariables_user} placeholder="variable" onChange={(value) => { messageAppend('user', value.value) }} />
                </div>
              </InputGroup>
              <InputGroup style={{ display: 'block' }}>
                <div>Add variable for added by: </div>
                <div style={{ width: '250px' }}>
                  <SelectStyled options={templateVariables_addedby} placeholder="variable" onChange={(value) => { messageAppend('added_by', value.value) }} />
                </div>
              </InputGroup>
              <InputGroup style={{ display: 'block' }}>
                <div>Add variable for registered by: </div>
                <div style={{ width: '250px' }}>
                  <SelectStyled options={templateVariables_registeredby} placeholder="variable" onChange={(value) => { messageAppend('registered_by', value.value) }} />
                </div>
              </InputGroup>
            </Col>
          </Row>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <InputGroup fullWidth style={{ margin: '18px', display: 'block' }}>
              <div>Message : </div>
              <textarea
                className='textP'
                placeholder='type Message'
                style={{ width: '480px', minHeight: '5rem' }}
                onChange={(event) => setMessage(event.target.value)}
                value={message}
              />
            </InputGroup>
          </Row>
        </CardBody>
        <CardFooter>
          <Row style={{ display: 'flex', justifyContent: 'center' }}>
            <Button status="Success" type="button" shape="SemiRound"
              style={{ margin: '10px', display: 'block' }}
              onClick={createNewTemplate}
              disabled={name && category && message && ((templateName || nameList.includes(name) != true)) ? false : true}
            >
              submit
            </Button>
            <Button status="Danger" type="button" shape="SemiRound"
              style={{ margin: '10px', display: 'block' }}
              onClick={() => onClose()}
            >
              cancel
            </Button>
          </Row>
        </CardFooter>
      </Card>
    </>
  )
}

export default Form;
