import React, { useState, useEffect, useRef } from "react";
import DataTable from 'react-data-table-component';
import { Button } from '@paljs/ui/Button';
import Modal from "./template-modal"
import _ from "lodash";
import { useLocation } from '@reach/router';
import { getAllTemplates, deleteTemplate, userDetail } from "../../actions/templateAction";
import LoadingSpinner from "../loader";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { uniq } from 'lodash';
import { ScheduleOutlined } from '@material-ui/icons';
import ScheduleWhatsAppModal from '../../components/whatsApp-messages/schedule-message';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';


const Templates = () => {
    const location = useLocation();
    const url = decodeURI(location.search);
    const params = new URLSearchParams(url);
    const nodeIdFromUrl = params.get('node_id');
    const [rows, setRows] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setEdit] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [mobile, setMobile] = useState();
    const [templateVariables, setTemplateVariables] = useState({});
    const [isLoading, setLoader] = useState(false);
    const [categoryListOptions, setCategoryListOptions] = useState([]);
    const [userName, setUserName] = useState('');
    const [existingNames, setExistingNames] = useState([]);
    const [isModalOPen, setModalOpen] = useState(false);
    const [data, setData] = useState({})

    const [toastData, setToastData] = useState<ToastrProps>({
        position: 'topEnd',
        status: 'Success',
        duration: 2000,
        hasIcon: true,
        destroyByClick: true,
        preventDuplicates: false,
    });
    const toastrRef = useRef<ToastrRef>(null);
    useEffect(() => {
        getTemplates();
    }, [isOpen]);

    const getTemplates = async () => {
        setLoader(true)
        const response = await getAllTemplates();
        const user = await userDetail(nodeIdFromUrl);
        setMobile(user.data.user.mobile);
        setUserName(user.data.user.name);
        setTemplateVariables({ ...user.data });
        const category = [];
        const nameList = [];
        const data = response.data.map((item) => {
            category.push(item.category);
            nameList.push(item.template_name);
            try {
                const compiled = _.template(item.message);
                return { ...item, 'message': compiled(user.data) }
            } catch (error) {
                console.error("An error occured during template compilation ", error)
                return item
            }

        });
        const finalCategoryList = uniq(category);
        const categoryList = [];
        finalCategoryList.map((item) => {
            categoryList.push({ value: item, label: item })
        });
        setRows(data);
        setLoader(false);
        setCategoryListOptions(categoryList);
        setExistingNames(nameList);
    };
    const tableColumns = [
        {
            name: 'name',
            selector: (row) => row.template_name,
            sortable: true,
        },
        {
            name: 'category',
            selector: (row) => row.category,
            sortable: true,
        },
        {
            name: 'Message',
            wrap: true,
            selector: (row) => row.message,
            sortable: true,
        },
        {
            name: 'action',
            cell: (row) =>
                <>
                    <IconButton aria-label="edit" onClick={() => { editMode(row.template_name) }}>
                        <EditIcon />
                    </IconButton>
                    <IconButton aria-label="edit"
                        onClick={() => {
                            if (window.confirm(`are you sure to delete "${row.template_name}" template`))
                                deleteTemplateByName(row.template_name)
                        }
                        }
                    >
                        <DeleteOutlineOutlinedIcon />
                    </IconButton>
                    <IconButton aria-label="whatsApp">

                        <a target="_blank" href={encodeURI(`https://wa.me/91${mobile}?text=${row.message}`)}>  <WhatsAppIcon /> </a>
                    </IconButton>
                    <IconButton aria-label="ScheduleOutlined"
                        onClick={() => {
                            const dataCollectedForRow = {
                                category: row.category,
                                message: row.message,
                                message_name: row.template_name,
                                medium: "WHATSAPP",
                                sent_to: nodeIdFromUrl,
                                mobile: mobile,
                            }
                            setData(dataCollectedForRow);
                            setModalOpen(true);
                        }}
                    >

                        <ScheduleOutlined />
                    </IconButton>
                </>
        }
    ];


    const deleteTemplateByName = async (name) => {
        await deleteTemplate(name);
        getTemplates();
    }

    const editMode = (name) => {
        setTemplateName(name);
        setEdit(true);
        setIsOpen(!isOpen);
    }
    const toggleNewTamplate = () => {
        setIsOpen(!isOpen);
        setTemplateName('');
        setEdit(false);
    }

    const templates = (
        <>
            <Toastr ref={toastrRef} />
            {isOpen && <Modal onClose={toggleNewTamplate} templateName={isEdit ? templateName : null} variables={templateVariables} categoryList={categoryListOptions} nameList={existingNames} />}
            {isModalOPen && <ScheduleWhatsAppModal data={data} onClose={() => { setModalOpen(false) }} onSubmit={(data) => {
                if (data) {
                    const toastTitle = 'Success';
                    const toastMessage = 'Message Scheduled sucessfully';
                    toastrRef.current?.add(toastMessage, toastTitle, { ...toastData });
                }
                else {
                    const toastTitle = 'Error';
                    const toastMessage = 'Error Occurred';
                    toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
                }
            }} />}
            {mobile ? <>
                <Button onClick={toggleNewTamplate}>create new Template</Button>
                <DataTable
                    title="user list"
                    columns={tableColumns}
                    data={rows}
                    pagination
                    paginationRowsPerPageOptions={[10, 20, 50, 100, 200, 500]}
                    paginationPerPage={100}
                    striped
                    dense
                    noHeader
                    highlightOnHover
                /></>
                :
                <h1 style={{ alignContent: 'center' }}>Mobile No. is not added for {userName}</h1>
            }
        </>
    )
    return <>{isLoading ? <LoadingSpinner message="Loading Templates" /> : templates}</>;
}

export default Templates;
